import { useState, useEffect } from "react";

interface Input<D = any> {
    onCompleted?: (data: D) => void
    onError?: (error: Error) => void
}


export interface AsyncState<D = any> {
    called: boolean;
    loading: boolean;
    error?: Error;
    data?: D;
}

type UseAsyncOutput<D> = [
    (func: () => Promise<D>) => Promise<D>,
    AsyncState<D>
];



export function useAsync<D>(input?: Input<D>): UseAsyncOutput<D> {
    const { onCompleted, onError } = input ?? {}

    const [called, setCalled] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<Error | undefined>();
    const [data, setData] = useState<D | undefined>();

    return [
        async (func) => {
            if (loading) {
                throw Error("Not allowed to call while previous call is running");
            }
            setData(undefined);
            setError(undefined);
            setCalled(true);
            setLoading(true);
            try {
                const res = await func();
                setData(res);
                setLoading(false);
                if (onCompleted) onCompleted(res)
                return res;
            } catch (err) {
                setLoading(false);
                setError(err || Error("Unknown async error"));
                if (onError) onError(err)
                throw err || Error("Unknown async error");
            }
        },
        { called, loading, error, data },
    ];
}

export function useEagerAsync<D>(func: () => Promise<D>): AsyncState<D> & { retry: () => void } {
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<Error | undefined>();
    const [data, setData] = useState<D | undefined>();


    const execute = async () => {
        setData(undefined);
        setError(undefined);
        setLoading(true)
        try {
            const data = await func()
            setData(data)
            setLoading(false)
        } catch (err) {
            setLoading(false);
            setError(err || Error("Unknown async error"));
        }
    }

    // Execute async call on mount
    useEffect(() => {
        execute()
    }, [])

    return {
        called: true,
        data,
        error,
        loading,
        retry: () => {
            if (loading) throw Error('Cannot retry while executing')
            execute()
        }
    }
}
