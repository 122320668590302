import React, { Dispatch, SetStateAction, useEffect, useState } from "react";

import { updateQueryString, parseQueryField, IQueryFieldParseOptions } from "../utility/query-string";

// Our hook
export function useDebounce<T>(value: T, delay): T {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState<T>(value);

    useEffect(
        () => {
            // Set debouncedValue to value (passed in) after the specified delay
            const handler = setTimeout(() => {
                setDebouncedValue(value);
            }, delay);

            // Return a cleanup function that will be called every time ...
            // ... useEffect is re-called. useEffect will only be re-called ...
            // ... if value changes (see the inputs array below).
            // This is how we prevent debouncedValue from changing if value is ...
            // ... changed within the delay period. Timeout gets cleared and restarted.
            // To put it in context, if the user is typing within our app's ...
            // ... search box, we don't want the debouncedValue to update until ...
            // ... they've stopped typing for more than 500ms.
            return () => {
                clearTimeout(handler);
            };
        },
        // Only re-call effect if value changes
        // You could also add the "delay" var to inputs array if you ...
        // ... need to be able to change that dynamically.
        [value]
    );

    return debouncedValue;
}

export function useDebouncedState<T>(
    value: T,
    delay
): [T | undefined, T | undefined, Dispatch<SetStateAction<T | undefined>>] {
    const [v, changeV] = useState(value);

    const debouncedV = useDebounce<T>(v, delay);

    return [v, debouncedV, changeV];
}

export function useQueryInputState<T>(args: IQueryFieldParseOptions): [T, Dispatch<SetStateAction<T>>] {
    let queryFieldValue = parseQueryField(window.location.search, args);

    const [ value, setValue ] = React.useState<T>(queryFieldValue);

    const doSetValue = (value) => {
        updateQueryString(args.queryField, value, args);
        setValue(value);
    }

    return [ value, doSetValue ];
}

export function useQueryInputStateDebounced<T>(args: IQueryFieldParseOptions & { delay: number }): [T, T, Dispatch<SetStateAction<T>>] {
    const [ value, setValue ] = useQueryInputState<T>(args);
    const [ value2, debouncedValue, setDebouncedValue ] = useDebouncedState<T>(value, args.delay);

    useEffect(() => {
        setDebouncedValue(value);
    }, [ value ])

    return [ value, debouncedValue, setValue ];
}

export function useMapValueToCallback<T>(value: T, cb: (v: T) => void) {
    useEffect(() => {
        cb(value);
    }, [value]);
}
