import {
    parse,
    stringify,
    StringifyOptions as qsStringifyOptions,
} from "query-string";
import moment from "moment";

type ValueType = number | string | Array<number | string> | Date;

interface StringifyOptions extends qsStringifyOptions {
    // Date format from moment library
    dateFormat?: string;
    // Default value
    default?: ValueType;
}

export const updateQueryString = (
    key: string,
    value: ValueType,
    options?: StringifyOptions
) => {
    if (value instanceof Date) {
        if (options.dateFormat) {
            value = moment(value).format(options.dateFormat);
        } else {
            value = value.toISOString();
        }
    }

    let searchText = window.location.search || ""
    
    while (searchText.startsWith("?")) {
        searchText = searchText.substr(1)
    }

    const parts = searchText ? searchText.split("&").filter(entry => !entry.startsWith(`${key}=`)) : []

    // We need to update only the field we just changed - nothing else.
    // This is because within this context we don't know the other fields should be formatted
    if (options?.default !== value && value !== null && value !== undefined) {
        const data = stringify({
            [key]: value
        }, options)

        if (data) {
            parts.push(data)
        }
    }

    parts.sort()

    const queryString = parts.join("&")

    const newurl =
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname +
        (queryString ? "?" + queryString : "");

    window.history.replaceState(
        {
            path: newurl,
        },
        "",
        newurl
    );
};

export interface IQueryFieldParseOptions {
    queryField: string;
    parseNumbers?: boolean;
    parseBooleans?: boolean;
    dateFormat?: string;
    arrayFormat?: "bracket" | "index" | "comma" | "separator" | "none";
    arraySeparator?: string;
    default?: any;
}

export function parseQueryField(value: string, args: IQueryFieldParseOptions) {
    const parsed = parse(value, args);
    let q: any = parsed[args.queryField];

    if (q !== undefined) {
        if (args.dateFormat) {
            if (Array.isArray(q)) {
                q = q.map((v) => moment(v, args.dateFormat).toDate());
            } else {
                q = moment(q, args.dateFormat).toDate();
            }
        }
    } else {
        q = args.default;
    }

    // If arrayFormat is specified and value is not array => convert it into an array
    if (args.arrayFormat && q && !Array.isArray(q)) {
        q = [q];
    }

    return q;
}
